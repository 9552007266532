import React from 'react';
import TenantPage from '../TenantPage/TenantPage';
import LaunchPad from '../Launchpad/Launchpad';
import { Route, Switch } from 'react-router-dom'

class App extends React.Component {
  render() {
    return (
      <div className="App ui container">
        <Switch>
          <Route exact path="/" component={LaunchPad} />
          <Route path="/:id" component={TenantPage} />
        </Switch>
      </div>
    );
  }

}

export default App;
