import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import SponsorModal from './SponsorModal';

class ConfirmationModal extends React.Component {
    state = { open: false };

    handleOpen = () => this.setState({ open: true })
    handleClose = () => {
        this.setState({ open: false });
        this.props.reloadPage();
    }

    render() {
        const selectedKids = this.props.selectedKids;
        const enabled = selectedKids.length > 0;
        const kids = selectedKids.length === 1 ? "child" : "kids";
        return (
            <Modal 
                trigger={<Button icon onClick={this.handleOpen} disabled={!enabled} positive={enabled}><Icon name="gift"/>Sponsor</Button>}
                dimmer={true} open={this.state.open} onClose={this.handleClose}>
                <Modal.Header>Please Confirm</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <h3>You are registring to buy gifts for {selectedKids.length} {kids} by December 4th.</h3>
                        <p>Note that siblings must be sponsored together.</p>
                        <h3>Would you like to proceed?</h3>
                    </Modal.Description>
                </Modal.Content>    
                <Modal.Actions>
                    <Button color='black' onClick={this.handleClose}>
                        No
                    </Button>
                    <SponsorModal 
                        selectedKids={this.props.selectedKids} 
                        tenant={this.props.tenant} 
                        closeParent={this.handleClose}
                        reloadPage={this.props.reloadPage}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ConfirmationModal;