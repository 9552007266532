import React from 'react';
import Welcome from '../Welcome/Welcome';
import TenantApp from '../TenantApp/TenantApp';

class TenantWelcome extends React.Component {
  state = { tenant: undefined }; 

  onTenantConfirm = async (tenant) => {
    this.setState({ tenant: tenant });
  }

  render() {
    return (
      <div className="App ui container">
        <Welcome open={this.state.tenant===undefined} tenant={this.props.tenant} onTenantConfirm={this.onTenantConfirm}/>
        {this.state.tenant !== undefined && <TenantApp tenant={this.state.tenant} />}
      </div>
    );
  }

}

export default TenantWelcome;