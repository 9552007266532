import React from 'react';
import { Message, Header, Menu, Grid, Dropdown } from 'semantic-ui-react';

class Filter extends React.Component {
    state = { gender: 'Either', age: 'Any', siblings: 'No' }

    filterCondition(kid, gender, age, siblings) {
        let filter = false;
        if ((gender === 'Either') || (kid.Gender === gender)) {
            if (age === 'Any')
                filter = true;
            else if (age === '4 and under')
                filter = kid.Age <= 4;
            else if (age === '5 - 9')
                filter = kid.Age > 4 && kid.Age < 10;
            else if (age === '10 - 12')
                filter = kid.Age > 9 && kid.Age < 13;
            else if (age === '13 and over')
                filter = kid.Age > 12;
        }
        if (filter) {
            const family = this.props.families.find((family) => family.Id === kid.FamilyId);
            filter = (siblings === 'No' && family.NumKids === 1) || (siblings === 'Yes' && family.NumKids > 1) || siblings === ' ';
        }
        return filter;
    }

    applyFilters(list, gender, age, siblings) {
        var filteredList = [];

        for (var i=0; i < list.length; i++) {
            if (this.filterCondition(list[i], gender, age, siblings)) {
                filteredList.push(list[i]);
            }
        }

        return filteredList;
    }

    handleGenderChange = (e, { value }) => {
        this.setState({ gender: value });
        const filteredList = this.applyFilters(this.props.unsponsored, value, this.state.age, this.state.siblings);
        this.props.onFilter(filteredList);
    }

    handleAgeChange = (e, { value }) => {
        this.setState({ age: value });
        const filteredList = this.applyFilters(this.props.unsponsored, this.state.gender, value, this.state.siblings);
        this.props.onFilter(filteredList);
    }

    siblingSelect = (e, { value }) => {
        this.setState({ gender: 'Either', age: 'Any', siblings: value });
        const filteredList = this.applyFilters(this.props.unsponsored, 'Either', 'Any', value);
        this.props.onFilter(filteredList);
    }

    render() {
        const genderOptions = [
            { key: 1, text: 'Either', value: 'Either' },
            { key: 2, text: 'Boy', value: 'Boy' },
            { key: 3, text: 'Girl', value: 'Girl' },
        ];
        const ageOptions = [
            { key: 1, text: 'Any', value: 'Any' },
            { key: 2, text: '4 and under', value: '4 and under' },
            { key: 3, text: '5 - 9', value: '5 - 9' },
            { key: 4, text: '10 - 12', value: '10 - 12' },
            { key: 5, text: '13 and over', value: '13 and over' },
        ];
        // const siblingOptions = [
        //     { key: 1, text: 'No', value: 'No' },
        //     { key: 2, text: 'Yes', value: 'Yes' },
        // ];
        const { gender, age, siblings } = this.state;
        return(
            <Message hidden={this.props.hidden}>
                <Grid verticalAlign='middle' columns={2} centered doubling stackable>
                    <Grid.Column textAlign="center" >
                        <Header>Select gender</Header>
                        <Menu compact>
                            <Dropdown options={genderOptions} onChange={this.handleGenderChange} value={gender} disabled={siblings==='Yes'} selection />
                        </Menu>

                    </Grid.Column>
                    <Grid.Column textAlign="center" >
                        <Header>Select an age range</Header>
                        <Menu compact>
                            <Dropdown options={ageOptions} onChange={this.handleAgeChange} value={age} disabled={siblings==='Yes'} selection />
                        </Menu>
                    </Grid.Column>
                    {/* <Grid.Column>
                        <Header>Sibling groups only</Header>
                        <Menu compact>
                            <Dropdown options={siblingOptions} onChange={this.siblingSelect} value={siblings} selection />
                        </Menu>
                    </Grid.Column> */}
                </Grid>
            </Message>   
        );
    }
} 

export default Filter;