import { Card, Feed } from 'semantic-ui-react'

const KidGiftContent = ({selectedKids}) => {
    const renderedList = selectedKids.map(k => {
        const restrictions = k.Restrictions ? "Yes" : "No";
        return (
            <div key={k.Id} className="ui segment" style={{ height:  '380px' }}>
                <Card.Header>
                    <div >
                        <b>{k.FirstName}</b>
                        <span style={{ float: 'right' }}>Tag# - {k.CasaId}</span>
                    </div>
                </Card.Header>
                <Card.Meta>
                    <span>{k.Age} year old {k.Gender}</span>
                </Card.Meta>
                <Feed>
                    <Feed.Label className="header">Wish list:</Feed.Label>
                    <Feed.Content>
                        <Feed.Summary>
                            <span id="giftprompt">I want:</span>
                            <div id="kidgift">{k.Want}</div>
                        </Feed.Summary>
                    </Feed.Content>
                    <Feed.Content>
                        <Feed.Summary>
                            <span id="giftprompt">I need:</span>
                            <div id="kidgift">{k.Need}</div>
                        </Feed.Summary>
                    </Feed.Content>
                    <Feed.Content>
                        <Feed.Summary>
                            <span id="giftprompt">To wear:</span>
                            <div id="kidgift">{k.Wear}</div>
                        </Feed.Summary>
                    </Feed.Content>
                    <Feed.Content>
                        <Feed.Summary>
                            <span id="giftprompt">To read:</span>
                            <div id="kidgift">{k.Read}</div>
                        </Feed.Summary>
                    </Feed.Content>
                    <br/>
                    <Feed.Content>
                        <Feed.Summary>
                            <span>Favorite color: </span>
                            <div id="kidgift">
                                {k.Color}
                            </div>
                        </Feed.Summary>
                    </Feed.Content>
                    <Feed.Content>
                        <Feed.Summary>
                            <span>Favorite TV or Movie character: </span>
                            <div id="kidgift">
                                {k.Movie}
                            </div>
                        </Feed.Summary>
                    </Feed.Content>
                    <br/>
                    <Feed.Content>
                        <Feed.Summary>
                            <span>Any gift restrictions?</span>
                            <div id="kidgift">
                                {restrictions}
                            </div>
                        </Feed.Summary>
                    </Feed.Content>
                    <Feed.Content>
                        <Feed.Summary>
                            <span>Notes / comments:</span>
                            <div id="kidgift">
                                {k.Notes}
                            </div>
                        </Feed.Summary>
                    </Feed.Content>
                </Feed>
            </div>
        );
    });

    return (
        <div>
            {renderedList}
        </div>
    );
};

export default KidGiftContent;