import './Stats.css';
import React from 'react';
import { Segment, Header } from 'semantic-ui-react';

class StatBar extends React.Component {

    formatMessages() {
        let kidMessage = "We have " + this.props.stats.NumKids + " kids in need of someone to buy them Christmas gifts.";
        let sponsorMessage = "You can be the first!";
        if (this.props.stats.NumSponsorKids > 0) {
            kidMessage = this.props.stats.NumSponsorKids + " out of " + this.props.stats.NumKids + " kids have been sponsored.";  
            sponsorMessage = "Now it is your turn!";
        }   
        if (!this.props.stats.NumKids) {
            kidMessage = "";
            sponsorMessage = "";
        } else if (this.props.stats.NumKids === this.props.stats.NumSponsorKids) {
            sponsorMessage = "But you can still donate below!"
        }
        return {kidMessage: kidMessage, sponsorMessage: sponsorMessage};
    }

    render() {
        const {kidMessage, sponsorMessage} = this.formatMessages();
        return (
            <Segment raised style={{backgroundColor: '#0E4577', color: 'white'}}>
                <Header id="stats" textAlign='center' style={{backgroundColor: '#0E4577', color: 'white'}}>
                    Calling all Santas! <span id="stats">{kidMessage}</span> {sponsorMessage}
                </Header>
            </Segment>
        );
    };
}

export default StatBar;