import React from 'react';
import { Button, Header, Image, Modal, Grid } from 'semantic-ui-react';
import KidGiftContent from './KidGiftContent';

class ReminderModal extends React.Component {
    state = { open: false }

    handleOpen = () => {
        this.setState({ open: true });
    } 

    handleClose = () => {
        this.setState({ open: false });
        this.props.closeParent();
        this.props.reloadPage();
    }

    render() {
        const selectedKids = this.props.selectedKids;
        const reminder = this.props.reminder;
        return(
            <Modal 
                trigger={<Button id='reminderButton' onClick={this.handleOpen} positive disabled={reminder}>Ok</Button>}
                dimmer={true} open={this.state.open} onClose={this.handleClose}>
                <Modal.Header>Thank you for Sponsoring!</Modal.Header>
                <Modal.Content image>
                    <Grid verticalAlign='middle' columns={2}>
                        <Grid.Column>
                            <Header></Header>
                            <Image
                            wrapped
                            size='medium'
                            src='https://casatree.blob.core.windows.net/media/BoyGirlSnowAngels.svg'
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Header>Time to go shopping for:</Header>
                            <KidGiftContent selectedKids={selectedKids}/>
                            <br/>
                            <br/>
                            <br/>
                            <Modal.Description>
                                <Header>Please drop your gifts at:</Header>
                                <address>
                                    {this.props.tenant.Address}
                                </address>
                                <p></p>
                                <p>Office hours:  {this.props.tenant.OfficeHours}</p>
                                <p>All gifts must be received by {this.props.tenant.EndDate}.</p>
                                <p></p>
                                <p>You will receive a reminder email with this information.</p>
                                <p>For questions, please call {this.props.tenant.Phone} or contact us at {this.props.tenant.Email}.</p>
                            </Modal.Description>
                        </Grid.Column>
                    </Grid>
    
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        positive
                        content="OK"
                        onClick={this.handleClose}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
} 

export default ReminderModal;