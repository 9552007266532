import './Maintenance.css';
import React from 'react';
import { Modal } from 'semantic-ui-react'

class Maintenance extends React.Component {

    render() {
        const open=this.props.open;
        const dimmer='blurring';
        return (
            <Modal id="elves" open={open} dimmer={dimmer} 
                header="The elves are working on it...we'll be back soon!"
            />
        );
    };
}

export default Maintenance;