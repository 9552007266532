import './KidGrid.css';
import React, { createRef } from 'react';
import { Grid, Ref, Sticky, Menu, Button, Icon, Header } from 'semantic-ui-react';
import Kid from './Kid';
import ConfirmationModal from './ConfirmationModal';
import Filter from './Filter';

class KidGrid extends React.Component {
    state = { filterHidden: true, selectedKids: [], unsponsored: [], nonSiblingsOnly: true };
    contextRef = createRef();

    reloadPage = () => {
        this.setState( { filterHidden: true, selectedKids: [], unsponsored: [], nonSiblingsOnly: true });
        this.props.reloadPage();
    }

    formatPrompt(selectedKids) {
        const second = !this.state.nonSiblingsOnly ? "Note - siblings have the same color and must be selected together." : "";
        if (selectedKids.length > 0) {
            if (selectedKids.length === 1)
                return {first: "Thank you for selecting a child.", 
                        second: second, third: "Please click Sponsor to complete your selection."};
            return {first: "Thank you for selecting " + selectedKids.length + " children.", 
                    second: second, third: "Please click Sponsor to complete your selection."};
        }
        return {first: "Please select one or more kids to buy their Christmas gifts.", 
                second: second, third: ""};
    }

    toggleFamily(familyId, isSelected, selected) {
        const inputList = this.props.kids;
        inputList.forEach((kid) => {
            if (kid.FamilyId === familyId) {
                kid.Sponsored = !kid.Sponsored;
                if (!isSelected) {
                    const loc = selected.findIndex( item => item.Id === kid.Id );
                    selected.splice(loc, 1);
                } else {
                    selected.push(kid);
                }
            }
        });
        return selected;
    }

    onSponsorToggle = (kid) => {
        let selected = this.state.selectedKids;
        const loc = selected.findIndex( item => item.Id === kid.Id );
        if (loc >= 0) {
            selected = this.toggleFamily(kid.FamilyId, false, selected);
        } else {
            selected = this.toggleFamily(kid.FamilyId, true, selected);
        }
        this.setState({ selectedKids: selected });
    }

    onFilter = (unsponsored) => {
        this.setState({ unsponsored: unsponsored });
    }
    
    onToggleFilterHidden = () => {
        this.setState({ filterHidden: !this.state.filterHidden });
        if (!this.state.filterHidden)
            this.setState({ unsponsored: [] });
    }
    
    onDonate = () => {
        window.open('https://casadenton.ejoinme.org/MyPages/DonationPage/tabid/518290/Default.aspx', '_blank');
    }

    addFamilyColors() {
        const colors = [
          { key: 1, boy: "https://casatree.blob.core.windows.net/media/BoySnowAngelGreenNonWhite.svg", girl: "https://casatree.blob.core.windows.net/media/GirlSnowAngelRedNonWhite.svg" },
          { key: 2, boy: "https://casatree.blob.core.windows.net/media/BoySnowAngelTealNonWhite.svg", girl: "https://casatree.blob.core.windows.net/media/GirlSnowAngelTealNonWhite.svg"},
          { key: 3, boy: "https://casatree.blob.core.windows.net/media/BoySnowAngelPurpleNonWhite.svg", girl: "https://casatree.blob.core.windows.net/media/GirlSnowAngelPurpleNonWhite.svg"},
          { key: 4, boy: "https://casatree.blob.core.windows.net/media/BoySnowAngelOrangeNonWhite.svg", girl: "https://casatree.blob.core.windows.net/media/GirlSnowAngelOrangeNonWhite.svg"},
          { key: 5, boy: "https://casatree.blob.core.windows.net/media/BoySnowAngelPinkNonWhite.svg", girl: "https://casatree.blob.core.windows.net/media/GirlSnowAngelPinkNonWhite.svg"}
        ];
        const familyList = this.props.families.map((f) => {
            let family = { Id: f.Id, NumKids: f.NumKids };
            if (f.NumKids > 1) {
                family["boy"] = colors[(f.Id % 2) + 1].boy;
                family["girl"] = colors[(f.Id % 2) + 1].girl;
            }
            else  {
                family["boy"] = colors[0].boy;
                family["girl"] = colors[0].girl;
            }
            return family;
        });
        return familyList;
    }

    onIndividuals = () => {
        this.setState({filterHidden: true, unsponsored: [], nonSiblingsOnly: true});
    }
    
    onSiblings = () => {
        this.setState({filterHidden: true, unsponsored: [], nonSiblingsOnly: false});
    }

    filterCondition(kid, families, nonSiblingsOnly) {
        const family = families.find((family) => family.Id === kid.FamilyId);  // perf optimization needed - consider CasaId/Tag#
        const filter = (nonSiblingsOnly && family.NumKids === 1) || (!nonSiblingsOnly && family.NumKids > 1);
        return filter;
    }

    applySiblingToggle(list, families, nonSiblingsOnly) {
        var filteredList = [];

        for (var i=0; i < list.length; i++) {
            if (this.filterCondition(list[i], families, nonSiblingsOnly)) {
                filteredList.push(list[i]);
            }
        }

        return filteredList;
    }

    render() {
        const families = this.addFamilyColors();
        const inputList = this.state.unsponsored.length > 0 || !this.state.filterHidden ? 
                            this.applySiblingToggle(this.state.unsponsored, families, this.state.nonSiblingsOnly) : 
                            this.applySiblingToggle(this.props.kids, families, this.state.nonSiblingsOnly);
        const renderedlist = inputList.map(kid => {
            const family = families.find((family) => family.Id === kid.FamilyId);
            return (
                <Kid 
                    key={kid.Id}
                    kid={kid} 
                    onSponsorToggle={this.onSponsorToggle}
                    family={family}
                />
            );
        });
        const {first, second, third} = this.formatPrompt(this.state.selectedKids);
        return (
            <div className="ui center raised segment">
                <Sticky context={this.contextRef}>
                    <div id="kid-grid-header">
                        <Button.Group id="button-group">
                            <Button primary={this.state.nonSiblingsOnly} active={this.state.nonSiblingsOnly} onClick={this.onIndividuals}>Buy for Individual Kids</Button>
                            <Button primary={!this.state.nonSiblingsOnly} active={!this.state.nonSiblingsOnly} onClick={this.onSiblings}>Buy for Brother/Sister Groups</Button>
                        </Button.Group>
                        <Header>{first}<p>{third}</p><span id="second-prompt">{second}</span></Header>
                        <Filter hidden={this.state.filterHidden} unsponsored={this.props.kids} families={families}
                                onFilter={this.onFilter} />
                        <Menu pointing secondary >
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <ConfirmationModal 
                                        selectedKids={this.state.selectedKids} 
                                        tenant={this.props.tenant} 
                                        reloadPage={this.reloadPage}/>
                                </Menu.Item>
                                <Menu.Item>
                                    <Button icon onClick={this.onToggleFilterHidden} disabled={!this.state.nonSiblingsOnly}><Icon name="child"/>Filter</Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <Button primary icon onClick={this.onDonate}><Icon name="dollar sign"/>Donate</Button>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                    </div>
                </Sticky>
                <Ref innerRef={this.contextRef}>
                    <Grid style={{ backgroundColor: '#fff', paddingTop: '2em' }} columns={3} centered doubling stackable>
                        {renderedlist}
                    </Grid>
                </Ref>
            </div>
        );
    }
}

export default KidGrid;