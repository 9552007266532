import './TenantCard.css';
import { Card, Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

export const TenantCard = (t) => {
    const tenant = t.tenant;
    const image = "https://casatree.blob.core.windows.net/media/casa-logo.jpg";
    const dest = "/" + tenant.Site;
    return (
        <Grid.Column>
            <Card id="card" as={Link} to={dest} >
                <Image src={image}></Image>
                <Card.Content>
                    <Card.Header>{tenant.Name}</Card.Header>
                </Card.Content>
            </Card>
        </Grid.Column>
    );
}