import './Welcome.css';
import React from 'react';
import { Modal, Segment, Button, Container } from 'semantic-ui-react';

class Welcome extends React.Component {
    state = { tenant: undefined };
    
    onTenantSelect = async (event, data) => {
        this.setState({ tenant: this.props.tenant });
        this.props.onTenantConfirm(this.props.tenant);
    }

    render() {
        const tenant = this.props.tenant;
        return (
            <Modal open={this.props.open}>
                <Modal.Header id="header" style={{backgroundColor: '#0E4577', color: 'white'}}>
                    Welcome to {tenant.Name}  
                </Modal.Header>
                <Modal.Content >
                    <Segment id="tenantSelect"  >
                        <Container id="container">

                            <p id="emphasis">You can save Christmas for some needy foster kids!</p>
                            <p id="emphasis">Here's how it works:</p>

                            <h3 id="rules">Find a child:</h3>
                            <span id="rules">1. Look through the list of kids.</span>
                            <span id="rules">2. Filter the list to focus your search. </span>
                            <span id="rules">3. Select one or more kids to buy for and press 'Sponsor'.</span>
                            <p></p>
                            <h4 id="rules">Buy and deliver gifts:</h4>
                            <span id="rules">1. Check the email with your child's wishlist.</span>
                            <span id="rules">2. Purchase your gifts and tag them with the child's tag number.</span>
                            <span id="rules">3. Drop your unwrapped gifts off at {tenant.Address} by {tenant.EndDate}.</span>
                            <span id="rules">4. It's that easy!  For questions, call {tenant.Phone} or email {tenant.Email}.</span>
                            <p></p>

                            <p id="emphasis">We suggest the following gift amounts:</p>

                            <span id="guidelines">Age  0-5:    {tenant.Under5Amount}</span>
                            <span id="guidelines">Age  6-12:   {tenant.Under12Amount}</span>
                            <span id="guidelines">Age 13-18:   {tenant.Under18Amount}</span>
                            <p></p>
                            <p id='final'>
                                If you wish to provide more, gift cards would be best and will be used for their unmet wants and needs. 
                            </p>
                            <Button positive onClick={this.onTenantSelect}>Let's go!</Button>

                        </Container>

                    </Segment>

                </Modal.Content>
            </Modal>

        );
    }
}

export default Welcome;