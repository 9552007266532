import React from 'react';
import casatree from '../../apis/casatree';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { TenantCard } from './TenantCard';
import LaunchpadHeader from './LaunchpadHeader';

class LaunchPad extends React.Component {
  state = { config: [], tenants: [], tenant: undefined }; 

  componentDidMount() {
    this.onPageLoad();
  }

  onPageLoad = async page => {
    const config = await casatree.get('/config');
    const tenants = await casatree.get('/tenants');

    this.setState({
      config: config.data,
      tenants: tenants.data,
    });
  }

  onTenantConfirm = async (tenant) => {
    this.setState({ tenant: tenant });
  }

  render() {
    const renderedlist = this.state.tenants.map(tenant => {
        return (
            <TenantCard key={tenant.Id} tenant={tenant} />
        );
    });
    return (
      <div className="App ui container">
        <LaunchpadHeader></LaunchpadHeader>
        <Segment raised style={{backgroundColor: '#0E4577', color: 'white'}}>
            <Header as="h1" textAlign='center' style={{backgroundColor: '#0E4577', color: 'white'}}>
                Supporting CASA Children of Texas 
            </Header>
        </Segment>
        <Grid style={{ backgroundColor: '#fff', paddingTop: '2em', paddingLeft: '20px' }} columns={3} centered doubling stackable>
            {renderedlist}
        </Grid>
      </div>
    );
  }

}

export default LaunchPad;
