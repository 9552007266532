import React from 'react';
import { Button, Header, Icon, Modal, Form, Input, Select } from 'semantic-ui-react';
import casatree from '../../apis/casatree';
import ReminderModal from './ReminderModal';
import {titleOptions, stateOptions} from './SelectOptions';

class SponsorModal extends React.Component {
    state = { 
        open: false, 
        title: '', 
        firstname: '', 
        lastname: '', 
        street: '', 
        city: '', 
        state: '', 
        zipcode: '', 
        phone: '', 
        email: '', 
        titleError: false,
        firstNameError: false,
        lastNameError: false,
        streetError: false,
        cityError: false,
        stateError: false,
        zipcodeError: false,
        phoneError: false,
        emailError: false,
        invalid: true,
        enabled: true
    }

    reset() {
        this.setState(
            { 
                open: false, 
                title: '', 
                firstname: '', 
                lastname: '', 
                street: '', 
                city: '', 
                state: '', 
                zipcode: '', 
                phone: '', 
                email: '', 
                titleError: false,
                firstNameError: false,
                lastNameError: false,
                streetError: false,
                cityError: false,
                stateError: false,
                zipcodeError: false,
                phoneError: false,
                emailError: false,
                invalid: true,
                enabled: true
            }
        );
    }

    handleOpen = () => this.setState({ open: true })

    handleClose = () => {
        this.reset();
        this.props.closeParent();
        this.props.reloadPage();
    }

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    buildSponsorship() {
        const sponsorship = {
            Title: this.state.title,
            FirstName: this.state.firstname,
            LastName: this.state.lastname,
            Street: this.state.street,
            City: this.state.city,
            State: this.state.state,
            Zipcode: this.state.zipcode,
            PhoneNumber: this.state.phone,
            Email: this.state.email,
            kids: []
        };
        this.props.selectedKids.map(kid => sponsorship.kids.push(kid.Id));
        //console.log(JSON.stringify(sponsorship));
        return sponsorship;
    }

    validForm = async () => {
        let error = false;
        if (this.state.title === '') {
            error = true;
        }
        if (this.state.firstname === '') {
            error = true;
        }
        if (this.state.lastname === '') {
            error = true;
        }
        if (this.state.street === '') {
            error = true;
        }
        if (this.state.city === '') {
            error = true;
        }
        if (this.state.state === '') {
            error = true;
        } 
        if (this.state.zipcode === '') {
            error = true;
        }
        if (this.state.phone === '' || !this.validatePhone(this.state.phone)) {
            error = true;
        } 
        if (this.state.email === '' || !this.validateEmail(this.state.email)) {
            error = true;
        }
        console.log("validForm: ", !error);
        return !error;
    }

    handleSubmit = async () => {
        if (!this.state.invalid) {
            this.setState({ invalid: true });
        }

        let error = false;
        if (this.state.title === '') {
            this.setState({titleError: true});
            error = true;
        } else {
            this.setState({titleError: false});
        }
        if (this.state.firstname === '') {
            this.setState({firstNameError: true});
            error = true;
        } else {
            this.setState({firstNameError: false});
        }
        if (this.state.lastname === '') {
            this.setState({lastNameError: true});
            error = true;
        } else {
            this.setState({lastNameError: false});
        }
        if (this.state.street === '') {
            this.setState({streetError: true});
            error = true;
        } else {
            this.setState({streetError: false});
        }
        if (this.state.city === '') {
            this.setState({cityError: true});
            error = true;
        } else {
            this.setState({cityError: false});
        }
        if (this.state.state === '') {
            this.setState({stateError: true});
            error = true;
        } else {
            this.setState({stateError: false});
        }
        if (this.state.zipcode === '') {
            this.setState({zipcodeError: true});
            error = true;
        } else {
            this.setState({zipcodeError: false});
        }
        if (this.state.phone === '' || !this.validatePhone(this.state.phone)) {
            this.setState({phoneError: true});
            error = true;
        } else {
            this.setState({phoneError: false});
        }
        if (this.state.email === '' || !this.validateEmail(this.state.email)) {
            this.setState({emailError: true});
            error = true;
        } else {
            this.setState({emailError: false});
        }

        if (!error) {
            this.setState({ invalid: false, enabled: false });
            const sponsorship = this.buildSponsorship();
            console.log("Confirm: " + this.props.tenant.Id + ": ", sponsorship)
            await casatree.post('/sponsorships/' + this.props.tenant.Id, sponsorship);
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePhone(phone) {
        const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        return re.test(String(phone).toLowerCase());
    }

    render() {
        const valid = this.validForm();
        const selectedKids = this.props.selectedKids;
        const enabled = selectedKids.length > 0;
        const kids = selectedKids.length === 1 ? "kid" : "kids";
        return (
            <Modal 
                trigger={<Button icon onClick={this.handleOpen} disabled={!enabled} positive={enabled}><Icon name="gift"/>Yes</Button>}
                dimmer={true} open={this.state.open} onClose={this.handleClose}>
                <Modal.Header>You have selected {selectedKids.length} {kids} to buy gifts for.  Thank you for Sponsoring!</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>Please let us know how to contact you:</Header>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group widths='equal'>
                                <Form.Field required fluid
                                    id='form-input-control-title'
                                    control={Select}
                                    options={titleOptions}
                                    label='Title'
                                    placeholder='Mrs.'
                                    name='title'
                                    onChange={this.handleChange}
                                    value={this.state.title}
                                    error={this.state.titleError}
                                />
                                <Form.Field required fluid
                                    id='form-input-control-first-name'
                                    control={Input}
                                    label='First name'
                                    placeholder='First name'
                                    name='firstname'
                                    onChange={this.handleChange}
                                    value={this.state.firstname}
                                    error={this.state.firstNameError}
                                />
                                <Form.Field required fluid
                                    id='form-input-control-last-name'
                                    control={Input}
                                    label='Last name'
                                    placeholder='Last name'
                                    name='lastname'
                                    onChange={this.handleChange}
                                    value={this.state.lastname}
                                    error={this.state.lastNameError}
                                />
                                <Form.Field required fluid
                                    id='form-input-control-phone'
                                    control={Input}
                                    label='Phone number'
                                    placeholder='555-111-2222'
                                    name='phone'
                                    onChange={this.handleChange}
                                    value={this.state.phone}
                                    error={this.state.phoneError}
                                />
                                <Form.Field required fluid
                                    id='form-input-control-email'
                                    control={Input}
                                    label='Email'
                                    placeholder='username@email.com'
                                    name='email'
                                    onChange={this.handleChange}
                                    value={this.state.email}
                                    error={this.state.emailError}
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field required fluid
                                    id='form-input-control-street'
                                    control={Input}
                                    label='Street'
                                    placeholder='Street Address'
                                    name='street'
                                    onChange={this.handleChange}
                                    value={this.state.street}
                                    error={this.state.streetError}
                                />
                                <Form.Field required fluid
                                    id='form-input-control-city'
                                    control={Input}
                                    label='City'
                                    placeholder='City'
                                    name='city'
                                    onChange={this.handleChange}
                                    value={this.state.city}
                                    error={this.state.cityError}
                                />
                                <Form.Field required fluid search
                                    id='form-input-control-state'
                                    control={Select}
                                    options={stateOptions}
                                    label='State'
                                    placeholder='state'
                                    name='state'
                                    onChange={this.handleChange}
                                    value={this.state.state}
                                    error={this.state.stateError}
                                />
                                <Form.Field required fluid
                                    id='form-input-control-zipcode'
                                    control={Input}
                                    label='Zipcode'
                                    placeholder='12345'
                                    name='zipcode'
                                    onChange={this.handleChange}
                                    value={this.state.zipcode}
                                    error={this.state.zipcodeError}
                                />
                            </Form.Group>
                            <Button type='submit' disabled={!this.state.enabled} primary={valid}>Confirm</Button>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.handleClose} disabled={!this.state.invalid && !this.state.enabled}>
                        Cancel
                    </Button>
                    <ReminderModal 
                        selectedKids={selectedKids} 
                        tenant={this.props.tenant}
                        reminder={this.state.invalid}
                        closeParent={this.handleClose}
                        reloadPage={this.props.reloadPage}/>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default SponsorModal;