import './Kid.css';
import React from 'react';
import { Card, Button, Image, Feed, Segment } from 'semantic-ui-react'

class Kid extends React.Component {

    formatImageSrc(kid, family) {
        if (kid.Gender==="Boy")
            return family.boy;
        return family.girl;
    }
    
    sponsorToggle(kid, onSponsorToggle) {
        onSponsorToggle(kid);
    }

    sponsorLabel(kid) {
        if (kid.Sponsored)
            return 'Selected';
        return 'Select';
    }

    render() {
        let k = this.props.kid;
        //const restrictions = k.Restrictions ? "Yes" : "No";
        const image = this.formatImageSrc(k, this.props.family);
        return (
            <div>
                <Segment style={{backgroundColor: '#0E4577'}}>
                    
                <Card id="kidcard" >
                    <Image id="kidimage" alt={k.FirstName} src={image} wrapped ui={false} />
                    <Card.Content>
                        <Card.Header>
                            {k.FirstName}
                        </Card.Header>
                        <Card.Meta>
                            <span>{k.Age} year old {k.Gender}</span>
                        </Card.Meta>
                        <Feed>
                            <Feed.Label className="header" id="header">Wish list:</Feed.Label>
                            <Feed.Content>
                                <Feed.Summary>
                                    <span id="giftprompt">I want:</span>
                                    <p id="kidgift">{k.Want}</p>
                                </Feed.Summary>
                            </Feed.Content>
                            <Feed.Content>
                                <Feed.Summary>
                                    <span id="giftprompt">I need:</span>
                                    <div id="kidgift">{k.Need}</div>
                                </Feed.Summary>
                            </Feed.Content>
                            <Feed.Content>
                                <Feed.Summary>
                                    <span id="giftprompt">To wear:</span>
                                    <div id="kidgift">{k.Wear}</div>
                                </Feed.Summary>
                            </Feed.Content>
                            <Feed.Content>
                                <Feed.Summary>
                                    <span id="giftprompt">To read:</span>
                                    <div id="kidgift">{k.Read}</div>
                                </Feed.Summary>
                            </Feed.Content>
                            <br/>
                            <Feed.Label id="noteprompt">Notes / comments:</Feed.Label>
                            <Feed.Content>
                                <Feed.Summary>
                                    <div id="note">
                                        {k.Notes}
                                    </div>
                                </Feed.Summary>
                            </Feed.Content>
                        </Feed>
                    </Card.Content>
                    <span id="tag">Tag# - {k.CasaId}</span>
                    <Card.Content extra textAlign="center">
                        <Button onClick={() => this.sponsorToggle(k, this.props.onSponsorToggle)} primary positive={k.Sponsored}>
                            {this.sponsorLabel(k)}
                        </Button>
                    </Card.Content>                
                </Card>
                </Segment>

                <span></span>
            </div>
        );
    }
}

export default Kid;