
export const titleOptions = [
    { key: 1, text: 'Mr.', value: 'Mr.' },
    { key: 2, text: 'Mrs.', value: 'Mrs.' },
    { key: 3, text: 'Ms.', value: 'Ms.' },
    { key: 4, text: 'Miss', value: 'Miss' },
];

export const stateOptions = [
    { key: 'al', text: 'Alabama', value: 'Alabama' },
    { key: 'ak', text: 'Alaska', value: 'Alaska' },
    { key: 'az', text: 'Arizona', value: 'Arizona' },
    { key: 'ar', text: 'Arkansas', value: 'Arkansas' },
    { key: 'ca', text: 'California', value: 'California' },
    { key: 'co', text: 'Colorado', value: 'Colorado' },
    { key: 'ct', text: 'Connecticut', value: 'Connecticut' },
    { key: 'de', text: 'Delaware', value: 'Delaware' },
    { key: 'fl', text: 'Florida', value: 'Florida' },
    { key: 'ga', text: 'Georgia', value: 'Georgia' },
    { key: 'hi', text: 'Hawaii', value: 'Hawaii' },
    { key: 'id', text: 'Idaho', value: 'Idaho' },
    { key: 'il', text: 'Illinois', value: 'Illinois' },
    { key: 'in', text: 'Indiana', value: 'Indiana' },
    { key: 'ia', text: 'Iowa', value: 'Iowa' },
    { key: 'ks', text: 'Kansas', value: 'Kansas' },
    { key: 'ky', text: 'Kentucky', value: 'Kentucky' },
    { key: 'la', text: 'Louisiana', value: 'Louisiana' },
    { key: 'me', text: 'Maine', value: 'Maine' },
    { key: 'md', text: 'Maryland', value: 'Maryland' },
    { key: 'ma', text: 'Massachusetts', value: 'Massachusetts' },
    { key: 'mi', text: 'Michigan', value: 'Michigan' },
    { key: 'mn', text: 'Minnesota', value: 'Minnesota' },
    { key: 'ms', text: 'Mississippi', value: 'Mississippi' },
    { key: 'mo', text: 'Missouri', value: 'Missouri' },
    { key: 'mt', text: 'Montana', value: 'Montana' },
    { key: 'ne', text: 'Nebraska', value: 'Nebraska' },
    { key: 'nv', text: 'Nevada', value: 'Nevada' },
    { key: 'nh', text: 'New Hampshire', value: 'New Hampshire' },
    { key: 'nj', text: 'New Jersey', value: 'New Jersey' },
    { key: 'nm', text: 'New Mexico', value: 'New Mexico' },
    { key: 'ny', text: 'New York', value: 'New York' },
    { key: 'nc', text: 'North Carolina', value: 'North Carolina' },
    { key: 'nd', text: 'North Dakota', value: 'North Dakota' },
    { key: 'oh', text: 'Ohio', value: 'Ohio' },
    { key: 'ok', text: 'Oklahoma', value: 'Oklahoma' },
    { key: 'or', text: 'Oregon', value: 'Oregon' },
    { key: 'pa', text: 'Pennsylvania', value: 'Pennsylvania' },
    { key: 'ri', text: 'Rhode Island', value: 'Rhode Island' },
    { key: 'sc', text: 'South Carolina', value: 'South Carolina' },
    { key: 'sd', text: 'South Dakota', value: 'South Dakota' },
    { key: 'tn', text: 'Tennessee', value: 'Tennessee' },
    { key: 'tx', text: 'Texas', value: 'Texas' },
    { key: 'ut', text: 'Utah', value: 'Utah' },
    { key: 'vt', text: 'Vermont', value: 'Vermont' },
    { key: 'va', text: 'Virginia', value: 'Virginia' },
    { key: 'wa', text: 'Washington', value: 'Washington' },
    { key: 'wv', text: 'West Virginia', value: 'West Virginia' },
    { key: 'wi', text: 'Wisconsin', value: 'Wisconsin' },
    { key: 'wy', text: 'Wyoming', value: 'Wyoming' },
];