import './Header.css';
import { Grid, Image } from 'semantic-ui-react'

const Header = () => {
    const image = "https://casatree.blob.core.windows.net/media/casa-logo.jpg";
    return (
        <div>
            <Grid stackable>
                <Grid.Column width={10}>
                    <div className="ui header">
                        <Image id="logo" src={image} size='huge' verticalAlign='top'></Image>
                        <h1 id="title">CASA Christmas</h1>
                    </div>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Grid.Row><a id="terms" href="https://casadashboard.z19.web.core.windows.net/" target="_blank" rel="noopener noreferrer">Admin Login</a></Grid.Row>
                    <Grid.Row><a id="terms" href="https://www.privacypolicyonline.com/live.php?token=07PSF9qPNIQ25A1vfVsTyoGdgZLrDHiL" target="_blank" rel="noopener noreferrer">Privacy</a></Grid.Row>
                    <Grid.Row><a id="terms" href="https://www.privacypolicyonline.com/live.php?token=LQEZ5bGRIm2yJxLRguIJzXIm4YwcfUZ3" target="_blank" rel="noopener noreferrer">Terms</a></Grid.Row>
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default Header;