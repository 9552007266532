import React, { useState, useEffect } from 'react'
import casatree from '../../apis/casatree';
import TenantWelcome from '../TenantWelcome/TenantWelcome';

export default function TenantPage(props) {
    const initialTenantState = {
        tenant: {},
        loading: true,
    }
    
    const [tenant, setTenant] = useState(initialTenantState);
    
    useEffect(() => {
        const getTenant = async () => {
            const { data } = await casatree.get('/sites/' + props.match.params.id);
            setTenant(data[0]);
        }

        getTenant();
    }, [props.match.params.id]);

    return tenant.loading ? (
        <div>Loading...</div>
    ) : (
        <TenantWelcome tenant={tenant} />
    );
};