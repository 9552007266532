import React from 'react';
import Maintenance from '../Maintenance/Maintenance';
import Header from '../Header/Header';
import StatBar from '../StatBar/StatBar';
import KidGrid from '../KidGrid/KidGrid';
import casatree from '../../apis/casatree';
import { Dimmer, Loader } from 'semantic-ui-react';

class TenantApp extends React.Component {
  state = { kids: [], families: [], stats: [], loading: true };

  componentDidMount() {
    this.onPageLoad();
  }

  genFamilies(kids) {
    const families = new Map();
    kids.data.forEach(k => {
      let family = families.get(k.FamilyId);
      if (!family) {
        family = { Id: k.FamilyId, TenantId: k.TenantId, NumKids: 1 };
      } else {
        family.NumKids++;
      }
      families.set(k.FamilyId, family);
    });
    return Array.from(families, ([name,value]) => (value));
  }

  onPageLoad = async page => {
    this.setState({ loading: true });

    const kids = await casatree.get('/unsponsored/' + this.props.tenant.Id);
    const stats = await casatree.get('/stats/' + this.props.tenant.Id);
    const families = this.genFamilies(kids);

    this.setState({
      families: families,
      kids: kids.data,
      stats: stats.data,
      loading: false
    });
  }

  render() {
    const open = false;
    return (
      <div className="App ui container">
        <Maintenance open={open}/>
        <Header />
        <Dimmer active={this.state.loading}>
            <Loader size='huge'>Loading</Loader>
        </Dimmer>
        <StatBar stats={this.state.stats}/> 
        <KidGrid 
            kids={this.state.kids} 
            families={this.state.families} 
            tenant={this.props.tenant} 
            reloadPage={this.onPageLoad}/>
      </div>
    );
  }

}

export default TenantApp;
