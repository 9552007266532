import './LaunchpadHeader.css';
import { Grid, Icon, Header } from 'semantic-ui-react'

const LaunchpadHeader = () => {
    return (
        <div id="topbanner">
            <Grid stackable>
                <Grid.Column width={10}>
                    <Header id="title">
                        <Icon name='child'></Icon>
                        Welcome to Better To Give!
                    </Header>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Grid.Row><a id="terms" href="https://casadashboard.z19.web.core.windows.net/" target="_blank" rel="noopener noreferrer">Admin Login</a></Grid.Row>
                    <Grid.Row><a id="terms" href="https://www.privacypolicyonline.com/live.php?token=07PSF9qPNIQ25A1vfVsTyoGdgZLrDHiL" target="_blank" rel="noopener noreferrer">Privacy</a></Grid.Row>
                    <Grid.Row><a id="terms" href="https://www.privacypolicyonline.com/live.php?token=LQEZ5bGRIm2yJxLRguIJzXIm4YwcfUZ3" target="_blank" rel="noopener noreferrer">Terms</a></Grid.Row>
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default LaunchpadHeader;